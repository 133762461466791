import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDefault from '../layouts/LayoutDefault'

// Komponen
import LoginSms from '../components/forms/LoginSms'
import Logo from '../components/headers/Logo'

export default function Sms() {
    return (
        <Fragment>
            <MetaTags>
            <title>Home | Aplikasi Surat Elektronik RSUP Persahabatan</title>
            <meta
                name="description"
                content="Halaman Surat Rahasia"
            />
            </MetaTags>
            <LayoutDefault>
                {/* KOMPONEN */}
                <Logo />
                <LoginSms />
            </LayoutDefault>
        </Fragment>
    )
}
