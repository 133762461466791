import React from 'react'
import { Link } from "react-router-dom"

function Logo() {
    return (
        <div className="page-single">
            <div className="text-center">
                <Link to={process.env.PUBLIC_URL + "/"}>
                    <img className="h-10" alt="Logo RSUP Persahabatan" src={process.env.PUBLIC_URL + '/logo-bawah.png'} />
                </Link>
            </div>
        </div>
    )
}

export default Logo
