import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Page }from "tabler-react"


// Load Komponen
import Header from "../components/headers/Atas"
import Footer from '../components/footers/Footer'


const LayoutDashboard = ({ children }) => { //console.log(children);
    return (
        <Fragment>
            <Page>
                <Header />
                    {children}
                <Footer />
            </Page>
        </Fragment>
    )
}

LayoutDashboard.propTypes = {
    children: PropTypes.any
}

export default LayoutDashboard
