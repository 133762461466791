import React, { Fragment } from 'react'
import { Link } from "react-router-dom"

import {
    Page,
    Container,
    Grid,
    PricingCard
  } from "tabler-react"

  import { FcKindle, FcKey, FcCollaboration } from "react-icons/fc"

export default function MenuList() {
    return (
        <Fragment>
            <Page.Content className="page-content-bottom" title="Pilih Menu">
                <Container>
                    <Grid.Row>
                        <Grid.Col sm={8} lg={4}>
                            <PricingCard>
                                <PricingCard.Category>{"Akses Internal"}</PricingCard.Category>
                                <PricingCard.Price><FcKindle /> </PricingCard.Price>
                                    <div className="text-center mt-6">
                                    <Link className="btn btn-secondary btn-block" to={process.env.PUBLIC_URL + "/login"}>
                                        Klik Akses Internal
                                    </Link>
                                    </div>
                            </PricingCard>
                        </Grid.Col>
                        <Grid.Col sm={8} lg={4}>
                            <PricingCard>
                                <PricingCard.Category>{"Akses Rahasia"}</PricingCard.Category>
                                <PricingCard.Price><FcKey /> </PricingCard.Price>
                                    <div className="text-center mt-6">
                                    <Link className="btn btn-secondary btn-block" to={process.env.PUBLIC_URL + "/private"}>
                                        Klik Akses Rahasia
                                    </Link>
                                    </div>
                            </PricingCard>
                        </Grid.Col>
                        <Grid.Col sm={8} lg={4}>
                            <PricingCard>
                                <PricingCard.Category>{"Akses Publik"}</PricingCard.Category>
                                <PricingCard.Price><FcCollaboration /> </PricingCard.Price>
                                <div className="text-center mt-6">
                                    <Link className="btn btn-secondary btn-block" to={process.env.PUBLIC_URL + "/publik"}>
                                        Klik Akses Publik
                                    </Link>
                                </div>
                            </PricingCard>
                        </Grid.Col>
                     </Grid.Row>
                </Container>
            </Page.Content>
        </Fragment>
    )
}
