import React, {useState,Fragment} from 'react'
import {
    Site,
    Nav
  } from "tabler-react"

import { NavLink } from 'react-router-dom'
import { ImHome,ImPencil2,ImBook,ImCompass,ImCogs } from "react-icons/im";


// Komponen

const Atas = () => {

    const [toggle, setToggle] = useState(false);

    return (
        <Fragment>
            <Site.Header
                imageURL={process.env.PUBLIC_URL + '/logo-bawah.png'}
                alt="Logo RSUP Persahabatan"
                notificationsTray={{
                    unread: true,
                    notificationsObjects: [
                        {
                            avatarURL: `${process.env.PUBLIC_URL + "/16.jpg"}`,
                            message: "Surat Masuk dari Sistem Informasi Rumah Sakit",
                            time: "10 minutes ago",
                        },
                        {
                            avatarURL: `${process.env.PUBLIC_URL + "/1.jpg"}`,
                            message: "Surat Disposisi Internal oleh Bapak Rohim.",
                            time: "1 hour ago",
                        },
                    ]
                }}
                accountDropdown={{
                    avatarURL: `${process.env.PUBLIC_URL + "/1.jpg"}`,
                    name: 'Much Desta Fadillah',
                    description: "Hacker Ganteng",
                    options: [
                        "profile",
                        "settings",
                        "divider",
                        "logout",                     
                    ]
                }}
                onMenuToggleClick={() => setToggle(!toggle)}
            >
            </Site.Header>
            <Site.Nav collapse={false}>
                <Nav>
                    <li className="nav-item"><NavLink className="nav-link" to="/dashboard"><ImHome />Dashboard</NavLink></li>
                    <li className="nav-item"><NavLink className="nav-link" to="/masuk"><ImBook />Surat Masuk</NavLink></li>
                    <li className="nav-item"><NavLink className="nav-link" to="/keluar"><ImPencil2 />Surat Keluar</NavLink></li>
                    <li className="nav-item"><NavLink className="nav-link" to="/disposisi"><ImCompass />Surat Disposisi</NavLink></li>
                    <li className="nav-item"><NavLink className="nav-link" to="/master"><ImCogs />Master Data</NavLink></li>
                </Nav>
            </Site.Nav>
        </Fragment>
    )
}

export default Atas
