import React, { Fragment } from 'react'
import {
    Page,
    Container,
    Grid,
    Card,
    colors,
    ProgressCard
  } from "tabler-react"

  import C3Chart from "react-c3js"

export default function Home() {
    return (
        <Fragment>
            <Page.Content title="Dashboard">
                <Container>
                    <Grid.Row cards={true}>
                        <Grid.Col sm={6} lg={3}>
                        <ProgressCard
                            header="Surat Masuk"
                            content="62"
                            progressColor="yellow"
                            progressWidth={100}
                        />                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <ProgressCard
                            header="Surat Keluar"
                            content="62"
                            progressColor="blue"
                            progressWidth={100}
                        />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <ProgressCard
                            header="Surat Disposisi Internal"
                            content="62"
                            progressColor="green"
                            progressWidth={100}
                        />
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <ProgressCard
                            header="Surat Disposisi Eksternal"
                            content="62"
                            progressColor="red"
                            progressWidth={100}
                        />
                        </Grid.Col>
                    </Grid.Row>
                </Container>
            </Page.Content>

            <Page.Content className="page-content-bottom" title="Grafik">
                <Container>
                    <Grid.Row cards={true}>
                        <Grid.Col sm={6} lg={3}>
                        <Card>
                                <Card.Header>
                                    <Card.Title>Surat Masuk</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                    style={{ height: "12rem" }}
                                    data={{
                                        columns: [
                                        // each columns data
                                        ["data1", 50],
                                        ["data2", 20],
                                        ["data3", 20],
                                        ["data4", 10]
                                        ],
                                        type: "donut", // default type of chart
                                        colors: {
                                        data1: colors["blue"],
                                        data2: colors["green-light"],
                                        data3: colors["red"],
                                        data4: colors['yellow']
                                        },
                                        names: {
                                        // name of each serie
                                        data1: "Dirmed",
                                        data2: "Dirut",
                                        data3: "Humas",
                                        data4: "Komkordik"
                                        },
                                    }}
                                    legend={{
                                        show: true, //hide legend
                                    }}
                                    padding={{
                                        bottom: 0,
                                        top: 0,
                                    }}
                                    />
                                </Card.Body>
                            </Card>                        
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <Card>
                                <Card.Header>
                                    <Card.Title>Surat Keluar</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                    style={{ height: "12rem" }}
                                    data={{
                                        columns: [
                                            // each columns data
                                            ['data1', 30, 20, 50, 40, 60, 50],
                                            ['data2', 200, 130, 90, 240, 130, 220],
                                            ['data3', 300, 200, 160, 400, 250, 250]
                                        ],
                                        type: "pie", // default type of chart
                                        colors: {
                                            data1: colors["green"],
                                            data2: colors["green-light"],
                                            data3: colors["yellow"]
                                        },
                                        names: {
                                            // name of each serie
                                            data1: "Humas",
                                            data2: "Dirmed",
                                            data3: "Yanmed",
                                        },
                                    }}
                                    legend={{
                                        show: true, //hide legend
                                    }}
                                    padding={{
                                        bottom: 0,
                                        top: 0,
                                    }}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <Card>
                                <Card.Header>
                                    <Card.Title>Disposisi Internal</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                    style={{ height: "12rem" }}
                                    data={{
                                        columns: [
                                        // each columns data
                                        ["data1", 63],
                                        ["data2", 37],
                                        ],
                                        type: "donut", // default type of chart
                                        colors: {
                                        data1: colors["green"],
                                        data2: colors["green-light"],
                                        },
                                        names: {
                                        // name of each serie
                                        data1: "Humas",
                                        data2: "Dirut",
                                        },
                                    }}
                                    legend={{
                                        show: false, //hide legend
                                    }}
                                    padding={{
                                        bottom: 0,
                                        top: 0,
                                    }}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Disposisi Eksternal</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                    style={{ height: "12rem" }}
                                    data={{
                                        columns: [
                                        // each columns data
                                        ["data1", 63],
                                        ["data2", 37],
                                        ],
                                        type: "donut", // default type of chart
                                        colors: {
                                        data1: colors["green"],
                                        data2: colors["green-light"],
                                        },
                                        names: {
                                        // name of each serie
                                        data1: "Maximum",
                                        data2: "Minimum",
                                        },
                                    }}
                                    legend={{
                                        show: false, //hide legend
                                    }}
                                    padding={{
                                        bottom: 0,
                                        top: 0,
                                    }}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Container>
            </Page.Content>
        </Fragment>
    )
}
