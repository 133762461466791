import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDashboard from '../layouts/LayoutDashboard'

// Komponen
import Keluar from '../components/konten/Keluar'

export default function SuratKeluar() {
    return (
        <Fragment>
            <MetaTags>
            <title>Surat Keluar | Dashboard Aplikasi Surat Elektronik</title>
            <meta
                name="description"
                content="Halaman Surat Keluar Aplikasi Surat Elektronik"
            />
            </MetaTags>
            <LayoutDashboard>

            <Keluar />
               
            </LayoutDashboard>
        </Fragment>
    )
}
