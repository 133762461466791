import React, { useState } from "react"
import { Button, Card, Container, Grid }from "tabler-react"
import { Form, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import ReactDatePicker  from "react-datepicker"
import moment from "moment"
import { RiCalendarEventLine,RiKeyLine } from "react-icons/ri"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

const schema = yup.object().shape({
    randomKey: yup.string().required('Wajib diisi sesuai kode yang dikirim melalui Email atau SMS'),
    dob: yup.string().required('Wajib diisi dengan Tanggal Lahir Penerima Surat'),
});

const RahasiaForm = () => {

    const { control, register, handleSubmit, formState:{ errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    // Tanggal Lahir
    const [selectedDate, setselectedDate] = useState(null);

    // source: https://stackoverflow.com/a/63491177 , https://stackoverflow.com/a/63331109
    const handleDobChange = (dateChange) => {
        setValue("dob", moment(dateChange).format("DD/MM/yyyy"), {
          shouldDirty: true
        });
        setselectedDate(dateChange);
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));

        console.log(data);

        // try {
        //     const response = await primaAPI.post("/GetSuratRahasia", formData, {
        //         mode: "no-cors",
        //         method: "POST",
        //         body: formData,
        //     });
        //     console.log("Submitting complete", response);  
        //     // setSuccessSubmit(response);
        // } catch (e) {
        //     console.log(e);
        // }
    };

    return (
        <div className="page-single">
            <Container>
                <Grid.Row>
                    <Grid.Col className="col-login mx-auto">
                    <Card>
                        <Card.Header>
                            <Card.Title>Form Surat Rahasia</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Kode Surat</Form.Label>
                                <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><RiKeyLine /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="randomKey"
                                    placeholder="Kode Surat"
                                    {...register("randomKey")}
                                />
                                    {errors.randomKey && 
                                        <Form.Text id="randomKeyHelpBlock" muted>
                                        {errors.randomKey?.message}
                                        </Form.Text>
                                    }
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tanggal Lahir</Form.Label>
                                <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><RiCalendarEventLine /></InputGroup.Text>
                                </InputGroup.Prepend>
                                
                                <Controller
                                    control={control}
                                    name="dob"
                                    render={({ field: {onBlur} }) => (
                                        <ReactDatePicker
                                            onChange={handleDobChange}
                                            onBlur={onBlur}
                                            selected={selectedDate}
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Tanggal Lahir (12/12/1970)"
                                        />
                                    )}
                                />
                                    {errors.dob && 
                                        <Form.Text id="dobHelpBlock" muted>
                                        {errors.dob?.message}
                                        </Form.Text>
                                    }
                                </InputGroup>
                            </Form.Group>
                            <Button type="submit" color="primary" className="ml-auto">
                                Submit
                            </Button>
                        </form>

                        </Card.Body>
                        <Card.Footer>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            <Button color="primary">Klik untuk Login dengan NIP</Button>
                        </Link>
                        </Card.Footer>
                    </Card>
                    </Grid.Col>
                </Grid.Row>
            </Container>
        </div>
    )
}

export default RahasiaForm