import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDefault from '../layouts/LayoutDefault'

// Komponen
import LoginForm from '../components/forms/LoginForm'
import Logo from '../components/headers/Logo'

export default function Login() {
    return (
        <Fragment>
            <MetaTags>
            <title>Masuk | Halaman Masuk Aplikasi Surat Elektronik</title>
            <meta
                name="description"
                content="Halaman Masuk Aplikasi Surat Elektronik"
            />
            </MetaTags>
            <LayoutDefault>
                {/* KOMPONEN */}
                <Logo />
                <LoginForm />
            </LayoutDefault>
        </Fragment>
    )
}
