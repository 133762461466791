import React, { Fragment, useState, useEffect } from 'react'
import {Site} from 'tabler-react'

// source: https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
let mobileSet = `/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)`


const Footer = () => {

    // source: https://stackoverflow.com/a/63440872
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = (width >= 768) && mobileSet ?'Personal Computer (PC)':'Mobile Browser, Tolong gunakan (Android/ iOS)';

    return (
        <Fragment>
            <div className="fixed-bottom">  
                <Site.Footer copyright= {`Copyright © 2021 RSUP Persahabatan. Dikembangkan oleh SIMRS. Kamu Membuka dengan ${isMobile} `} />
            </div>
        </Fragment>        
    )
}

export default Footer
