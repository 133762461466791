import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDashboard from '../layouts/LayoutDashboard'

// Komponen
import Home from '../components/konten/Home'

export default function Dashboard() {
    return (
        <Fragment>
            <MetaTags>
            <title>Beranda | Dashboard Aplikasi Surat Elektronik</title>
            <meta
                name="description"
                content="Halaman Dashboard Aplikasi Surat Elektronik"
            />
            </MetaTags>
            <LayoutDashboard>

            <Home />
               
            </LayoutDashboard>
        </Fragment>
    )
}
