import React, { Fragment } from 'react'
import {
    Page,
    Container,
    Grid,
    Card,
    Button
  } from "tabler-react"

  import { FcBusinessman, FcConferenceCall, FcAddressBook, FcBriefcase, FcSpeaker, FcBusiness } from "react-icons/fc"

export default function Master() {
    return (
        <Fragment>
            <Page.Content title="Setup Aplikasi">
                <Container fluid="md">
                    <Grid.Row cards alignItems="center">
                        <Grid.Col>
                        <Card>
                        <div className="card-body text-center">
                            <p className="mb-4">
                                <Button href="#">
                                <FcBusinessman size="200px"/>
                                Pengguna Surat
                                </Button>
                            </p>
                        </div>
                        </Card>                  
                        </Grid.Col>
                        <Grid.Col>
                            <Card>
                        <div className="card-body text-center">
                            <p className="mb-4">
                                <Button href="#">
                                <FcConferenceCall size="200px"/>
                                Jabatan
                                </Button>
                            </p>
                        </div>
                        </Card>
                        </Grid.Col>
                        <Grid.Col>
                            <Card>
                        <div className="card-body text-center">
                            <p className="mb-4">
                                <Button href="#">
                                <FcAddressBook size="200px"/>
                                Custom Tujuan
                                </Button>
                            </p>
                        </div>
                        </Card>
                        </Grid.Col>
                        <Grid.Col>
                            <Card>
                        <div className="card-body text-center">
                        <p className="mb-4">
                            <Button href="#">
                            <FcBriefcase size="200px"/>
                            Entitas Surat
                            </Button>
                        </p>
                        </div>
                        </Card>
                        </Grid.Col>
                        <Grid.Col>
                            <Card>
                        <div className="card-body text-center">
                        <p className="mb-4">
                            <Button href="#">
                            <FcSpeaker size="200px"/>
                            Notifikasi
                            </Button>
                        </p>
                        </div>
                        </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Container>
            </Page.Content>

            <Page.Content className="page-content-bottom" title="Master Data">
                <Container>
                <Grid.Row cards alignItems="center">
                <Grid.Col>
                    <Card>
                        <div className="card-body text-center">
                        <p className="mb-4">
                            <Button href="#">
                            <FcBusiness size="200px"/>
                            Notifikasi
                            </Button>
                        </p>
                        </div>
                    </Card>
                </Grid.Col>
                <Grid.Col>
                <Card>
                        <div className="card-body text-center">
                        <p className="mb-4">
                            <Button href="#">
                            <FcBusiness size="200px"/>
                            Notifikasi
                            </Button>
                        </p>
                        </div>
                    </Card>
                </Grid.Col>
                </Grid.Row>
                </Container>
            </Page.Content>
        </Fragment>
    )
}
