import React from "react"
import { Button, Card, Container, Form, Grid } from "tabler-react"
import { Link } from "react-router-dom"

const LoginForm = () => {
    return (
        <div className="page-single">
            <Container>
                <Grid.Row>
                    <Grid.Col className="col-login mx-auto">
                    <Card>
                        <Card.Header>
                            <Card.Title>Form NIP Aktif</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        <Form onSubmit={event => console.log(event.target.name + "clicked")}>
                            <Form.Input icon="user" type="text" name="nip" label="NIP" placeholder="Ketik NIP" />
                            <Form.Input icon="alert-circle" type="password" name="password" label="Password" placeholder="Ketik Password" />
                            <Button color="primary" type="submit">Submit</Button>
                            </Form>
                        </Card.Body>
                        <Card.Footer> 
                        <Link to={process.env.PUBLIC_URL + "/sms"}>
                            Login dengan OTP SMS
                        </Link>
                        </Card.Footer>
                    </Card>
                    </Grid.Col>
                </Grid.Row>
            </Container>
        </div>
        
    )
}

export default LoginForm