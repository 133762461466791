import React from "react"
import { Button, Card, Container, Form, Grid } from "tabler-react"

const LoginSms = () => {
    return (
        <div className="page-single">
            <Container>
                <Grid.Row>
                    <Grid.Col className="col-login mx-auto">
                    <Card>
                        <Card.Header>
                            <Card.Title>Form OTP SMS</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        <Form onSubmit={event => console.log(event.target.name + "clicked")}>
                            <Form.Input icon="phone" name="nomorhp" label="Nomor HP" placeholder="Ketik Nomor HP" />
                            <Button color="primary" type="submit">Submit</Button>
                            </Form>
                        </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Container>
        </div>
        
    )
}

export default LoginSms