import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDefault from '../layouts/LayoutDefault'

// Komponen
import SuratView from '../components/konten/SuratView'

export default function Surat() {
    return (
        <Fragment>
            <MetaTags>
            <title>Surat | Lihat Surat Masuk</title>
            <meta
                name="description"
                content="Lihat Surat Masuk"
            />
            </MetaTags>
            <LayoutDefault>
                {/* KOMPONEN */}
                <SuratView />
            </LayoutDefault>
        </Fragment>
    )
}
