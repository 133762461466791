import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDashboard from '../layouts/LayoutDashboard'

// Komponen
import Master from '../components/konten/Master'


export default function MasterSetup() {
    return (
        <Fragment>
            <MetaTags>
            <title>Surat Master &amp; Setup | Dashboard Aplikasi Surat Elektronik</title>
            <meta
                name="description"
                content="Halaman Surat Master &amp; Setup Aplikasi Surat Elektronik"
            />
            </MetaTags>
            <LayoutDashboard>

            <Master />
               
            </LayoutDashboard>
        </Fragment>
    )
}
