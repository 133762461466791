import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDashboard from '../layouts/LayoutDashboard'

// Komponen
import Masuk from '../components/konten/Masuk'

export default function SuratMasuk() {
    return (
        <Fragment>
            <MetaTags>
            <title>Surat Masuk | Dashboard Aplikasi Surat Elektronik</title>
            <meta
                name="description"
                content="Halaman Surat Masuk Aplikasi Surat Elektronik"
            />
            </MetaTags>
            <LayoutDashboard>

            <Masuk />
               
            </LayoutDashboard>
        </Fragment>
    )
}
