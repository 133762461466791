import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import LayoutDefault from '../layouts/LayoutDefault'

// Komponen
import PublikForm from '../components/forms/PublikForm'
import Logo from '../components/headers/Logo'

export default function Publik() {
    return (
        <Fragment>
            <MetaTags>
            <title>Surat | Form Login Publik</title>
            <meta
                name="description"
                content="Form Login Publik"
            />
            </MetaTags>
            <LayoutDefault>
                {/* KOMPONEN */}
                <Logo />
                <PublikForm />
            </LayoutDefault>
        </Fragment>
    )
}
