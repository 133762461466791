import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Komponen
import PilihMenu from "./screens/PilihMenu";
import Login from './screens/Login';
import Rahasia from './screens/Rahasia';
import Sms from './screens/Sms';
import Surat from './screens/Surat';
import Publik from './screens/Publik'

import Dashboard from './screens/Dashboard';
import SuratMasuk from './screens/SuratMasuk';
import SuratKeluar from './screens/SuratKeluar';
import SuratDisposisi from './screens/SuratDisposisi';
import Master from './screens/MasterSetup';


// Private
// import { PrivateRoute } from './helpers/PrivateRoute';


function App() {
  return (
    <Router>
        <Switch>

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={PilihMenu}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/login"}`}
            component={Login}
          />

          <Route
                path={`${process.env.PUBLIC_URL + "/private"}`}
                component={Rahasia}
          />

          <Route
                path={`${process.env.PUBLIC_URL + "/surat"}`}
                component={Surat}
          />

          <Route
                path={`${process.env.PUBLIC_URL + "/sms"}`}
                component={Sms}
          />

          <Route
                path={`${process.env.PUBLIC_URL + "/publik"}`}
                component={Publik}
          />

          <Route component={Dashboard} path="/dashboard" />
          <Route component={SuratMasuk} path="/masuk" />
          <Route component={SuratKeluar} path="/keluar" />
          <Route component={SuratDisposisi} path="/disposisi" />
          <Route component={Master} path="/master" />

          {/* 
          <Route render={(props) => <Dashboard pageName="dashboard" {...props} />} path="/dashboard" />
          <Route render={(props) => <SuratMasuk pageName="masuk" {...props} />} path="/masuk" />
          <Route render={(props) => <SuratKeluar pageName="keluar" {...props} />} path="/keluar" />
          <Route render={(props) => <SuratDisposisi pageName="disposisi" {...props} />} path="/disposisi" />
          <Route render={(props) => <Master pageName="master" {...props} />} path="/master" /> 
          */}


		</Switch>
	</Router>
  );
}

export default App;
