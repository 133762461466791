import React from "react"
import { Card, Grid, Page } from "tabler-react"
import { Container, Row, Col, Media, Table, Image } from "react-bootstrap"

// Tested, ada 2 Pilihan Library yang digunakan.
import fileDownload from 'js-file-download'
import fileSaver from 'file-saver'
import axios from 'axios'

const SuratView = ({ data }) => {

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
        .then((res) => {
            fileDownload(res.data, filename)
        })
    }

    const saveFile = () => {
        fileSaver.saveAs(   
            process.env.REACT_APP_CLIENT_URL + "/supports/office/277491885b169829406200479497aa7b.xlsx", "Data_Pegawai_Ukom_Periode_April_2021.xlsx"
        )
    };

    return (
        <div className="page-single">
            <Container fluid>
                <Page.Content title="Lihat Surat">
                    <Grid.Row>
                        <Grid.Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className="mb-1 text-center">
                                    <Card.Title><img width="100%" alt="Logo RSUP Persahabatan" src={process.env.PUBLIC_URL + '/headersurat.png'} /></Card.Title>
                                </div>
                                <div className="card-title text-center">
                                    <div className="display-5 font-weight-bold mb-4">NOTA DINAS</div>
                                    <div className="display-6 font-weight-bold mb-6">Nomor: UM/6.3/000101/2021</div>
                                </div>
                                <Table responsive>
                                <tbody>
                                    <tr>
                                        <td>Yth</td>
                                        <td>:</td>
                                        <td>Sistem Informasi Rumah Sakit</td>
                                    </tr>
                                    <tr>
                                        <td>Dari</td>
                                        <td>:</td>
                                        <td>Plh Kepala Bagian Hukum, Organisasi dan Hubungan Masyarakat</td>
                                    </tr>
                                    <tr>
                                        <td>Hal</td>
                                        <td>:</td>
                                        <td>Rahasia Ini Untuk Desta</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal</td>
                                        <td>:</td>
                                        <td>06 April 2021</td>
                                    </tr>
                                </tbody>
                                </Table>

                                <Row>
                                <Col sm={12}>
                                    <Media.Body>
                                    <p>
                                        Upaya peningkatan mutu perlu dilakukan secara terus menerus dan berkelanjutan, salah satunya dengan penerapan standar - standar akreditasi rumah sakit. Sebagai bentuk tanggung jawab dalam menjamin terlaksanya pelayanan yang terstandarisasi maka dibentuk Tim Self Asessmen Standar Akreditasi oleh Direktur Utama. Tahap awal Tim akan melakukan telusur ke 5 unit kerja pelayanan untuk dapat melihat gambaran penerapan standar-standar akreditasi  di pelayanan saat ini, sebagai data awal untuk melakukan perbaikan mutu berkelanjutan. Kegiatan akan kami lakukan tanggal 13 - 15 April 2021.
                                        Mohon dapat dipersiapkan data dukung dan proses implementasi di unit Bapak/Ibu.
                                        Atas kerja sama Bapak/Ibu disampaikan terima kasih.
                                    </p>
                                    </Media.Body>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={7}></Col>
                                    <Col sm={5}>
                                        <Card className="text-center">
                                            <Card.Header>
                                                <small>Plh Kepala Bagian Hukum, Organisasi dan Hubungan Masyarakat,</small>
                                            </Card.Header>
                                            <Card.Body>
                                                <Image  src={process.env.PUBLIC_URL + '/barcode.png'} fluid />
                                            </Card.Body>
                                            <Card.Footer>
                                                <small className="text-muted">dr. VONNY YAHYA, MH</small>
                                            </Card.Footer>
                                        </Card>    
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        Lampiran:
                                        <ul className="list-unstyled">
                                            {/* source: https://stackoverflow.com/a/64002765 */}
                                            <li><button onClick={() => {handleDownload('http://192.168.132.2/supports/office/277491885b169829406200479497aa7b.xlsx', 'Data_Pegawai_Ukom_Periode_April_2021.xlsx')}}>Data_Pegawai_Ukom_Periode_April_2021.xlsx</button></li>
                                            {/* source: https://stackoverflow.com/a/63439983 */}
                                            <li><button className="cv" onClick={saveFile}>Data_Pegawai_Ukom_Periode_April_2021.xlsx</button></li>
                                        </ul>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
            </Container>
        </div>
        
    )
}

export default SuratView