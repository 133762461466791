import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Page }from "tabler-react"

const LayoutDefault = ({ children }) => { //console.log(children);
    return (
        <Fragment>
            <Page>
                {children}
            </Page>
        </Fragment>
    )
}

LayoutDefault.propTypes = {
    children: PropTypes.any
}

export default LayoutDefault
