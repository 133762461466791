import React, { Fragment, useState } from 'react'

import {
    Page,
    Container,
    Grid,
    Card,
    Button
} from "tabler-react"

import {
Pagination,
PaginationOpts,
TableHeader,
TableBody,
Filter,
useDatatableLifecycle,
shouldTableUpdate
} from 'react-bs-datatable'


import { Dropdown,DropdownButton } from 'react-bootstrap'

import { useForm, Controller } from "react-hook-form"
import ReactDatePicker  from "react-datepicker"
import moment from "moment"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"


const CustomTable = React.memo(props => {
    const {
      data,
      rowsPerPageOption,
      tableHeaders,
      onChangeFilter,
      onPageNavigate,
      classes,
      onRowsPerPageChange,
      onSortChange,
      tableClass,
      labels,
      filterable,
      filterText,
      rowsPerPage,
      currentPage,
      sortedProp,
      maxPage,
      Components
    } = useDatatableLifecycle(props);

    return (
        <>
          <Components.Row className="controlRow__root">
            <Components.Col xs="12">
              <Filter
                classes={classes}
                tableHeaders={tableHeaders}
                placeholder={labels.filterPlaceholder}
                onChangeFilter={onChangeFilter}
                filterText={filterText}
                filterable={filterable}
                components={{
                  Adornment: Components.Adornment,
                  Button: Components.Button,
                  ClearIcon: Components.ClearIcon,
                  FormControl: Components.FormControl,
                  InputGroup: Components.InputGroup
                }}
              />
            </Components.Col>
          </Components.Row>
          <Components.Row>
            <Components.Col xs="12">
              <Components.Table className={tableClass}>
                <TableHeader
                  classes={classes}
                  tableHeaders={tableHeaders}
                  sortedProp={sortedProp}
                  onSortChange={onSortChange}
                  components={{
                    TableHead: Components.TableHead,
                    TableCell: Components.TableCell,
                    TableRow: Components.TableRow
                  }}
                />
                <TableBody
                  classes={classes}
                  tableHeaders={tableHeaders}
                  labels={labels}
                  data={data}
                  components={{
                    TableBody: Components.TableBody,
                    TableCell: Components.TableCell,
                    TableRow: Components.TableRow
                  }}
                />
              </Components.Table>
            </Components.Col>
          </Components.Row>
          <Components.Row className="controlRow__root bottom">
            <Components.Col xs={12} md={4} />
            <Components.Col xs={12} md={4}>
              <PaginationOpts
                classes={classes}
                labels={labels}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPage={rowsPerPage}
                rowsPerPageOption={rowsPerPageOption}
                components={{
                  Form: Components.Form,
                  FormGroup: Components.FormGroup,
                  FormControl: Components.FormControl
                }}
              />
            </Components.Col>
            <Components.Col xs={12} md={4} className="text-right">
              <Pagination
                classes={classes}
                data={data}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                onPageNavigate={onPageNavigate}
                labels={labels}
                maxPage={maxPage}
                components={{
                  Button: Components.Button,
                  ButtonGroup: Components.ButtonGroup
                }}
              />
            </Components.Col>
          </Components.Row>
        </>
      );
    }, shouldTableUpdate);

const header = [
    {
        title: 'Username (filterable)',
        prop: 'username',
        sortable: true,
        filterable: true
    },
    { title: 'Name', prop: 'realname', sortable: true },
    {
        title: 'Name Uppercased',
        prop: 'realnameuppercase',
        cell: row => row.realname.toUpperCase()
    },
    { title: 'Location', prop: 'location' },
    { title: 'Last Updated', prop: 'date', sortable: true },
    { title: 'Action', prop: 'random', sortable: true , cell: row => ( [<Button>View</Button>,<Button>Disposisi</Button>,<Button>Balas</Button>]) }

];

const body = Array.from(new Array(57), () => {
    const rd = (Math.random() * 10).toFixed(1);
  
    if (rd > 0.5) {
      return {
        username: 'i-am-billy',
        realname: `Billy ${rd}`,
        location: 'Mars',
        date: moment()
          .subtract(1, 'days')
          .format('Do MMMM YYYY')
      };
    }
  
    return {
      username: 'john-nhoj',
      realname: `John ${rd}`,
      location: 'Saturn',
      date: moment()
        .subtract(2, 'days')
        .format('Do MMMM YYYY')
    };
});

const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    }
};
  
const customLabels = {
    first: '<<',
    last: '>>',
    prev: '<',
    next: '>',
    show: 'Display',
    entries: 'rows',
    noResults: 'There are no data to be displayed'
};

const schema = yup.object().shape({
    randomKey: yup.string().required('Wajib diisi sesuai kode yang dikirim melalui Email atau SMS'),
    dob: yup.string().required('Wajib diisi dengan Tanggal Lahir Penerima Surat'),
});

export default function Masuk() {

    const { control, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    // Tanggal Lahir
    const [selectedDate, setselectedDate] = useState(null);

    // source: https://stackoverflow.com/a/63491177 , https://stackoverflow.com/a/63331109
    const handleDobChange = (dateChange) => {
        setValue("dob", moment(dateChange).format("DD/MM/yyyy"), {
            shouldDirty: true
        });
        setselectedDate(dateChange);
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));

        console.log(data);

        // try {
        //     const response = await primaAPI.post("/GetSuratRahasia", formData, {
        //         mode: "no-cors",
        //         method: "POST",
        //         body: formData,
        //     });
        //     console.log("Submitting complete", response);  
        //     // setSuccessSubmit(response);
        // } catch (e) {
        //     console.log(e);
        // }
    };

    return (
        <Fragment>
            <Page.Content title="Surat Masuk">
                <Container>
                <Card>
                <Card.Header>
                    <Card.Title>Filter Pencarian </Card.Title>
                </Card.Header>
                <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid.Row cards={true}>
                        <Grid.Col sm={6} lg={3}>
                            <DropdownButton id="dropdown-basic-button" title="Pengirim">
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </DropdownButton>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                            <DropdownButton id="dropdown-basic-button" title="Penerima">
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </DropdownButton>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>                            
                            <DropdownButton id="dropdown-basic-button" title="Diterima">
                                <Dropdown.Item href="#/action-1">Ya</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Tidak</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">All</Dropdown.Item>
                            </DropdownButton>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>
                        <Controller
                            control={control}
                            name="dob"
                            render={({ field: {onBlur} }) => (
                                <ReactDatePicker
                                    onChange={handleDobChange}
                                    onBlur={onBlur}
                                    selected={selectedDate}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Tanggal Kirim"
                                />
                            )}
                        />
                        </Grid.Col>
                        </Grid.Row>

                </form>
                </Card.Body>
                <Card.Footer>  <Button color="primary">Cari</Button></Card.Footer>
                </Card>
                
                </Container>
            </Page.Content>

            <Page.Content className="page-content-bottom">
                <Container>
                    <Grid.Row>
                        <Grid.Col width={12}>
                        <Card title="List Surat">
                        <CustomTable
                            tableHeaders={header}
                            tableBody={body}
                            rowsPerPage={5}
                            rowsPerPageOption={[5, 10, 15, 20]}
                            initialSort={{ prop: 'username', isAscending: true }}
                            onSort={onSortFunction}
                            labels={customLabels}
                        />
                        </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Container>
            </Page.Content>
        </Fragment>
    )
}
